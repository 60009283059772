<template>
  <el-card style="min-height: 345px">
    <Header slot="header" :title="accessory.name" :subTitle="roomName" :icon="accessory.icon"></Header>

    <div class="content-container">

      <div style="height: 230px">
        <div class="progress" @click="onSwitch" style="cursor: pointer">
          <el-progress type="circle" :percentage="accessory.level"></el-progress>
        </div>

        <el-slider v-model="accessory.level" @change="onChangeLevel"></el-slider>
      </div>


    </div>

  </el-card>
</template>

<script>
import {accessoryMixin} from "./accessoryMixin";

export default {
    name: "Dimmer",

    mixins: [accessoryMixin],
    
    methods: {
      onSwitch: function () {
        this.accessory.powerOn = !this.accessory.powerOn
        this.createAction("setOn", this.accessory.powerOn)
      },
      onChangeLevel: function () {
        if (this.accessory.level > 0)
          this.accessory.powerOn = true
        else
          this.accessory.powerOn = false

        this.createAction("setDeviceLevel", this.accessory.level)
      }
    },
  }
</script>

<style scoped>

  .progress {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 180px;
  }

  .column-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 250px;
    height: 230px;
  }

  .content-container {
    height: 230px;
  }
</style>