<template>
  <el-card style="min-height: 345px">
    <Header slot="header" :title="accessory.name" :subTitle="roomName" :icon="accessory.icon"></Header>

    <div class="content-container">

      <div @click="onSwitch" class="progress">
        <el-progress type="circle" :percentage="accessory.powerOn ? 100 : 0" status="text">{{ accessory.powerOn ? $t('scene.lock') : $t('scene.unlock') }}</el-progress>
      </div>
    </div>

  </el-card>
</template>

<script>
import {accessoryMixin} from "./accessoryMixin";

export default {
  name: "DoorLock",

  mixins: [accessoryMixin],

  methods: {
      onSwitch: function () {
        this.accessory.powerOn = !this.accessory.powerOn
        this.createAction("setOn", this.accessory.powerOn)
      }
    },
  }
</script>

<style scoped>
  .icon {
    filter: brightness(0.45);
    width: 40px;
    height: 40px;
  }
  .title {
    font-size: small;
    font-weight: bold;
    color: #545c64;
  }

  .subtitle {
    font-size: smaller;
    color: #545c64;
  }

  .progress {
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 230px;
  }

  .content-container {
    height: 230px;
  }
</style>