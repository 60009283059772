import {className} from "../../../utils/accessory-utils";
import { LOCAL_MODE, LOCAL_IP_2 } from "../../../store/constants";
import Header from "./Header";

export const accessoryMixin = {
  components: {Header},

  props: {
    accessory: {
      type: Object,
      required: true,
      default() {
            return {}
        }
    }
  },

  data: function(){
    return {
      connection: null,
      actions:[]
    }
  },

  computed: {
    roomName: function () {
      var room = this.$store.getters.rooms.find(room => room.id == this.accessory.roomId)
      if (room)
        return room.name
      return this.$t('scene.no-room')
    }
  },

  methods: {
    className,
    createAction: function(action, targetValue, valueTitle, targetMode) {
      console.log("createAction", action, targetValue, valueTitle);

      const params = {};
      params.id = this.accessory.id;
      if (action) {
        params.action = action;
      }
      if (targetValue != null && targetValue != undefined) {
        params.newTargetValue = targetValue;
      }
      if (targetMode != null && targetMode != undefined) {
        params.targetMode = targetMode;
      }
      if (valueTitle) {
        params.valueTitle = valueTitle;
      }
      console.log(params)
      this.runAction(params)
    },
    runAction : function(params){
      let connection = new WebSocket(  LOCAL_MODE ? LOCAL_IP_2 : "ws://" + location.hostname + ":6655",);
      connection.onopen = () => connection.send(JSON.stringify(
        {
          "id":Math.floor(Math.random()*90000) + 10000,
          "method":"sendCommand",
          "params": params,
          "body":{}
        }));
    }
    // createAction: function (service, paramValue, paramTitle) {

    //   console.log("createAction", service, paramValue, paramTitle)

    //   const params = {}
    //   if (paramValue != null && paramValue != undefined) {
    //     params.newTargetValue = paramValue
    //   }

    //   if (paramTitle) {
    //     params.valueTitle = paramTitle
    //   }

    //   const action = {
    //     accessory: {
    //       type: this.className(this.accessory.category),
    //       properties: {...this.accessory}
    //     },
    //     service: service,
    //     argumentList: params
    //   }

    //   console.log("createAction", action)

    //   this.addSceneAction(action)
    //   this.actions= [];
    //   this.actions.push({...action});
    //   console.log("Actions", this.actions)
    //   this.$emit("onAction", {accessory: this.accessory, actions: [...this.actions]})
    // },
  },

}