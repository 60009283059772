var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticStyle: {
        display: "flex",
        "justify-content": "space-between",
        "align-items": "flex-end"
      }
    },
    [
      _c("div", [
        _c("span", { staticClass: "title" }, [
          _vm._v(" " + _vm._s(_vm.title) + " ")
        ]),
        _c("br"),
        _c("span", { staticClass: "subtitle" }, [
          _vm._v(" " + _vm._s(_vm.subTitle) + " ")
        ])
      ]),
      _c("img", {
        staticClass: "icon",
        attrs: { src: "/assets/icons/" + _vm.icon + ".png", alt: "" }
      })
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }