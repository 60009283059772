<template>
  <el-card style="min-height: 345px;">
    <Header slot="header" :title="accessory.name" :subTitle="roomName" :icon="accessory.icon"></Header>

    <div class="content-container">

      <div>
        <el-tabs v-model="tabValue" tab-position="bottom">

          <el-tab-pane name="temperature" label="Temperature">

            <el-row>
              <el-col :span="8" class="column-container">

                <div class="mode-control">
                  <div class="mode-button" :style="{color: accessory.onStatus ? 'dodgerblue' : '#CCCCCC'}" @click="onSetStatus('onStatus', 'offStatus')">I</div>
                  <div class="mode-button" :style="{color: accessory.offStatus ? '#545c64' : '#CCCCCC'}" @click="onSetStatus('offStatus', 'onStatus')">0</div>
                </div>


              </el-col>

              <el-col :span="8" class="column-container">
                <div class="increase-button" @click="increase()"><i class="fa fa-angle-up fa-2x"></i></div>
                <div class="progress">
                  <el-progress type="circle" :percentage="percentage" color="dodgerblue" status="text">{{ status }}<span>&#176;</span></el-progress>
                </div>

                <div class="increase-button" @click="decrease()"><i class="fa fa-angle-down fa-2x"></i></div>
              </el-col>

              <el-col :span="8" class="column-container">
                <div>{{ $t('scene.current') }}</div>
                <div>{{ accessory.temperature }}&#176;</div>
              </el-col>

            </el-row>

          </el-tab-pane>

          <el-tab-pane v-for="component in accessory.components" :key="component.type" :name="component.type" :label="component.title">

            <div style="height: 180px; width: 100%; display: flex; justify-content: center; align-items: center; flex-wrap: wrap">

              <div v-for="mode in component.modes" :key="mode.type" :class="{'component-mode-button-selected': mode.selected}"  class="component-mode-button" @click="onSelectMode(component, mode)">
                <img  :src="'/assets/icons/' + mode.icon + '.png'" class="icon-contact">
                <div style="white-space: nowrap">{{ mode.title }}</div>
              </div>

            </div>

          </el-tab-pane>



        </el-tabs>
      </div>

    </div>

  </el-card>
</template>

<script>
  import {accessoryMixin} from "./accessoryMixin";

  export default {
    name: "AdvHvac",

    mixins: [accessoryMixin],

    data: function(){
      return {
        currentIndex: 0,
        tabValue: "temperature",
      }
    },

    computed: {
      percentage: function () {
        return 100 * this.accessory.targetSetPoint / this.accessory.targetSetPoints[this.accessory.targetSetPoints.length - 1]
      },

      status: function () {
          return this.accessory.targetSetPoint
      },
      isSelected: function (mode) {
          return mode.selected ? true : false
      },
    },

    methods: {
      onSetStatus: function (status1, status2) {
        this.accessory[status1] = true
        this.accessory[status2] = false

        this.createAction(status1 == "onStatus" ? "actionOn" : "actionOff")
      },

      increase(){
        if (this.currentIndex < this.accessory.targetSetPoints.length - 1) {
          const newValue = this.accessory.targetSetPoints[++this.currentIndex]
          this.accessory.targetSetPoint = newValue

          this.createAction("setTargetPoint", this.accessory.targetSetPoint)
        }
      },

      decrease(){
        if (this.currentIndex > 0) {
          const newValue = this.accessory.targetSetPoints[--this.currentIndex]
          this.accessory.targetSetPoint = newValue
          this.createAction("setTargetPoint", this.accessory.targetSetPoint)
        }
      },

      onSelectMode(component, selectedMode) {
        console.log(selectedMode);
        this.createAction("setComponentMode", null, null, selectedMode.type)
        component.modes = component.modes.map(mode => {
          mode.selected = mode.type === selectedMode.type
          return mode
        })


      },


      getCurrentIndex: function () {
        const targetPoints = this.accessory.targetSetPoints
        var maxDiff = Number.MAX_VALUE

        console.log("MAXXX", maxDiff)

        var index = 0
        for (var i = 0; i < targetPoints.length; i++) {
          const diff = Math.abs(targetPoints[i] - this.accessory.targetSetPoint)
          maxDiff = Math.min(maxDiff, diff)

          if (maxDiff == diff)
            index = i
        }

        console.log("Current Index", index)

        return index
      }
    },

    created(){
      this.currentIndex = this.getCurrentIndex()
    }
  }
</script>

<style scoped>

  .column-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 180px;
  }

  .progress {
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .mode-control {
    border: 1px solid #cccccc;
    border-radius: 8px;
    padding-top: 5px;
    padding-bottom: 5px;
  }

  .mode-button {
    cursor: pointer;
    padding: 10px;
    margin: 2px;
    color: #ccc;
    text-align: center;
  }

  .increase-button{
    color: #545c64;
    cursor: pointer;

  }

  .increase-button:hover{
    color: dodgerblue;
  }

  .icon-contact {
    width: 24px;
    height: 24px;
  }

  .component-mode-button {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    margin: 5px;
    background-color: #545c64;
    color: #eeeeee;
    font-size: 12px;

    width: 60px;
    height: 60px;

    border-radius: 4px;

    cursor: pointer;
  }

  .component-mode-button-selected {
    /*border: 3px solid dodgerblue;*/
    background-color: #00BFD7;
  }

  .component-mode-button:hover {
    /*border: 3px solid dodgerblue;*/
    background-color: #00BFD7;
  }

  .component-mode-button:active {
    /*border: 3px solid dodgerblue;*/
    background-color: #00BFD7;
  }

  .content-container {
    height: 230px;
  }

</style>