var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-card",
    { staticStyle: { "min-height": "345px" } },
    [
      _c("Header", {
        attrs: {
          slot: "header",
          title: _vm.accessory.name,
          subTitle: _vm.roomName,
          icon: _vm.accessory.icon
        },
        slot: "header"
      }),
      _vm.accessory.unit == ""
        ? _c("div", { staticClass: "content-container" }, [
            _c(
              "div",
              { staticClass: "progress", on: { click: _vm.onSwitch } },
              [
                _c(
                  "el-progress",
                  {
                    attrs: {
                      type: "circle",
                      percentage: _vm.accessory.value == "1" ? 100 : 0,
                      status: "text"
                    }
                  },
                  [
                    _vm._v(
                      _vm._s(
                        _vm.accessory.value == "1"
                          ? _vm.$t(_vm.accessory.details.onValueText)
                          : _vm.$t(_vm.accessory.details.offValueText)
                      )
                    )
                  ]
                )
              ],
              1
            )
          ])
        : _c("div", { staticClass: "content-container" }, [
            _c(
              "div",
              { staticClass: "progress", on: { click: _vm.onSwitch } },
              [
                _c(
                  "el-progress",
                  { attrs: { type: "circle", percentage: 0, status: "text" } },
                  [
                    _vm._v(
                      _vm._s(_vm.accessory.value + " " + _vm.accessory.unit)
                    )
                  ]
                )
              ],
              1
            )
          ])
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }