var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-card",
    { staticStyle: { "min-height": "345px" } },
    [
      _c("Header", {
        attrs: {
          slot: "header",
          title: _vm.accessory.name,
          subTitle: _vm.roomName,
          icon: _vm.accessory.icon
        },
        slot: "header"
      }),
      _c("div", { staticClass: "content-container" }, [
        _c("div", { staticClass: "container" }, [
          _c(
            "div",
            {
              staticClass: "up-circle",
              on: {
                click: function($event) {
                  return _vm.onAction("actionOn")
                }
              }
            },
            [_c("span", [_vm._v("I")])]
          ),
          _c(
            "div",
            {
              staticClass: "down-circle",
              on: {
                click: function($event) {
                  return _vm.onAction("actionOff")
                }
              }
            },
            [_c("span", [_vm._v("O")])]
          )
        ])
      ])
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }