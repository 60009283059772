<template>
  <el-card style="min-height: 345px">
    <Header slot="header" :title="accessory.name" :subTitle="roomName" :icon="accessory.icon"></Header>

    <div class="content-container">
      <div @click="onSwitch" class="progress">
        <el-progress type="circle" :percentage="accessory.powerOn ? 100 : 0" status="text">{{ accessory.powerOn ? $t('scene.on') : $t('scene.off') }}</el-progress>
      </div>
    </div>

  </el-card>
</template>

<script>
// import {className} from "../../../utils/accessory-utils";
// import Header from "./Header.vue";
import {accessoryMixin} from "./accessoryMixin";

export default {
    name: "Switch",

    mixins: [accessoryMixin],
    
    methods: {
      onSwitch: function () {
        this.accessory.powerOn = !this.accessory.powerOn
        this.createAction("setOn", this.accessory.powerOn)
      }

    },
  }

</script>

<style scoped>

  .progress {
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 230px;
  }

  .content-container {
    height: 230px;
  }
</style>