<template>
  <el-card style="min-height: 345px;">
    <Header slot="header" :title="accessory.name" :subTitle="roomName" :icon="accessory.icon"></Header>

    <div class="content-container">

      <div class="container">
        <div @click="onAction('actionOn')" class="up-circle">
          <span>I</span>
        </div>

        <div @click="onAction('actionOff')" class="down-circle">
          <span>O</span>
        </div>
      </div>

    </div>

  </el-card>
</template>

<script>
  import {accessoryMixin} from "./accessoryMixin";
  import { LOCAL_MODE, LOCAL_IP_2 } from "../../../store/constants";

  export default {
    name: "Binary",

    mixins: [accessoryMixin],

    methods: {
      onAction: function (action) {
        this.createAction(action)
      }
    },
  }
</script>

<style scoped>

  .container {
    cursor: pointer;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 230px;
  }

  .up-circle {
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 60px;
    width: 120px;
    border-radius: 60px 60px 0px 0px;
    border: 5px solid #e5e9f2;
  }

  .up-circle:active {
    background-color: #545c64;
  }

  .down-circle {
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 60px;
    width: 120px;
    margin-top: -5px;
    border-radius: 0px 0px 60px 60px;
    border: 5px solid #e5e9f2;
  }

  .down-circle:active {
    background-color: #545c64;
  }

  .content-container {
    height: 230px;
  }

</style>