var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "el-row",
        {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.loading,
              expression: "loading"
            }
          ],
          attrs: { gutter: 10 }
        },
        _vm._l(_vm.allAccessories, function(accessory) {
          return _c(
            "el-col",
            {
              key: accessory.accessoryId,
              staticStyle: { "margin-top": "10px" },
              attrs: { span: 8 }
            },
            [
              _vm.className(accessory.category) == "HHAccessorySwitch" &&
              accessory.category == 27
                ? _c("punchComponent", {
                    attrs: { accessory: accessory },
                    on: { onAction: _vm.onAction }
                  })
                : _vm.className(accessory.category) == "HHAccessorySwitch" &&
                  accessory.category == 28
                ? _c("binaryComponent", {
                    attrs: { accessory: accessory },
                    on: { onAction: _vm.onAction }
                  })
                : _vm.className(accessory.category) == "HHAccessorySwitch"
                ? _c("switchComponent", {
                    attrs: { accessory: accessory },
                    on: { onAction: _vm.onAction }
                  })
                : _vm.className(accessory.category) == "HHAccessoryDoorLock"
                ? _c("doorLockComponent", {
                    attrs: { accessory: accessory },
                    on: { onAction: _vm.onAction }
                  })
                : _vm.className(accessory.category) == "HHAccessoryDimmer"
                ? _c("dimmerComponent", {
                    attrs: { accessory: accessory },
                    on: { onAction: _vm.onAction }
                  })
                : _vm.className(accessory.category) == "HHAccesssoryThermostat"
                ? _c("hvacComponent", {
                    attrs: { accessory: accessory },
                    on: { onAction: _vm.onAction }
                  })
                : _vm.className(accessory.category) ==
                  "HHAccessoryAdvThermostat"
                ? _c("advHvacComponent", {
                    attrs: { accessory: accessory },
                    on: { onAction: _vm.onAction }
                  })
                : _vm.className(accessory.category) ==
                  "HHAccessoryWindowCovering"
                ? _c("shutterComponent", {
                    attrs: { accessory: accessory },
                    on: { onAction: _vm.onAction }
                  })
                : _vm.className(accessory.category) == "HHAccessoryAudio"
                ? _c("audioComponent", {
                    attrs: { accessory: accessory },
                    on: { onAction: _vm.onAction }
                  })
                : _vm.className(accessory.category) == "HHAccessoryRGBWDimmer"
                ? _c("rgbwDimmerComponent", {
                    attrs: { accessory: accessory },
                    on: { onAction: _vm.onAction }
                  })
                : _vm.className(accessory.category) ==
                  "HHAccessoryStatusDisplay"
                ? _c("statusDisplay", { attrs: { accessory: accessory } })
                : _vm.className(accessory.category) == "HHAccessorySensor"
                ? _c("sensor", { attrs: { accessory: accessory } })
                : _vm._e()
            ],
            1
          )
        }),
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }