<template>
  <el-card style="min-height: 345px">
    <Header slot="header" :title="accessory.name" :subTitle="roomName" :icon="accessory.icon"></Header>

    <div v-if="accessory.unit ==''" class="content-container">
      <div @click="onSwitch" class="progress">
        <el-progress type="circle" :percentage="accessory.value == '1' ? 100 : 0" status="text">{{ accessory.value == '1' ? $t(accessory.details.onValueText) : $t(accessory.details.offValueText) }}</el-progress>
      </div>
    </div>
    <div v-else class="content-container">
      <div @click="onSwitch" class="progress">
        <el-progress type="circle" :percentage="0" status="text">{{ accessory.value +' '+ accessory.unit }}</el-progress>
      </div>
    </div>

  </el-card>
</template>

<script>
import {accessoryMixin} from "./accessoryMixin";

export default {
    name: "Switch",

    mixins: [accessoryMixin],
    
    methods: {
      onSwitch: function () {
      }

    },
  }

</script>

<style scoped>

  .progress {
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 230px;
  }

  .content-container {
    height: 230px;
  }
</style>