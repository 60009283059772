<template>
  <div>
    <el-row :gutter="10" v-loading="loading" >
      <el-col v-for="accessory in allAccessories" :key="accessory.accessoryId"  :span="8" style="margin-top: 10px">
        <punchComponent v-if="className(accessory.category) == 'HHAccessorySwitch' && accessory.category == 27"
            :accessory="accessory"
            @onAction="onAction"
          />
        <binaryComponent v-else-if="className(accessory.category) == 'HHAccessorySwitch' && accessory.category == 28"
            :accessory="accessory"
            @onAction="onAction"
          />
        <switchComponent v-else-if="className(accessory.category) == 'HHAccessorySwitch'"
            :accessory="accessory"
            @onAction="onAction" 
          />
        <doorLockComponent v-else-if="className(accessory.category) == 'HHAccessoryDoorLock'"
            :accessory="accessory"
            @onAction="onAction" 
          />   
        <dimmerComponent v-else-if="className(accessory.category) == 'HHAccessoryDimmer'"
            :accessory="accessory"
            @onAction="onAction" 
          />   
        <hvacComponent v-else-if="className(accessory.category) == 'HHAccesssoryThermostat'"
            :accessory="accessory"
            @onAction="onAction" 
          /> 
        <advHvacComponent v-else-if="className(accessory.category) == 'HHAccessoryAdvThermostat'"
            :accessory="accessory"
            @onAction="onAction" 
          /> 
        <shutterComponent v-else-if="className(accessory.category) == 'HHAccessoryWindowCovering'"
            :accessory="accessory"
            @onAction="onAction" 
          />  
        <audioComponent v-else-if="className(accessory.category) == 'HHAccessoryAudio'"
            :accessory="accessory"
            @onAction="onAction" 
          /> 
        <rgbwDimmerComponent v-else-if="className(accessory.category) == 'HHAccessoryRGBWDimmer'"
            :accessory="accessory"
            @onAction="onAction" 
          />  
        <statusDisplay v-else-if="className(accessory.category) == 'HHAccessoryStatusDisplay'"
            :accessory="accessory"
          /> 
        <sensor v-else-if="className(accessory.category) == 'HHAccessorySensor'"
            :accessory="accessory"
          />   
      </el-col>
    </el-row>
  </div>

</template>

<script>

import Switch from "./components/Switch";
import Punch from "./components/Punch.vue";
import DoorLock from "./components/DoorLock.vue";
import Dimmer from "./components/Dimmer.vue";
import Shutter from "./components/Shutter.vue";
import Binary from "./components/Binary.vue";
import Hvac from "./components/Hvac.vue";
import AdvHvac from "./components/AdvHvac.vue";
import Audio from "./components/Audio.vue";
import RGBWDimmer from "./components/RGBWDimmer.vue";
import StatusDisplay from "./components/StatusDisplay.vue";
import Sensor from "./components/Sensor.vue";

import { getActions, className,} from "../../utils/accessory-utils";
import { LOCAL_MODE, LOCAL_IP_2 } from "../../store/constants";

import {
  getFiltersFromLocalStorage,
  // setFiltersToLocalStorage,
  // removeFiltersFromLocalStorage,
} from '@/api/accessory'

export default {
  name: "Control",

  components: {
    switchComponent: Switch,
    punchComponent: Punch,
    doorLockComponent: DoorLock,
    dimmerComponent: Dimmer,
    shutterComponent: Shutter,
    binaryComponent: Binary,
    hvacComponent: Hvac,
    advHvacComponent: AdvHvac,
    audioComponent: Audio,
    rgbwDimmerComponent: RGBWDimmer,
    statusDisplay: StatusDisplay,
    sensor: Sensor,

  },

  data: function() {
    return {
      loading: true,
      accessories: [],
      timer : null,
      connection: null,
      webSocketData: [],
      actionList: [],
    };
  },

  computed: {
    allAccessories: function() {
      return this.$store.getters.accessories
      // return this.$store.getters.accessories.filter(accessory => {
      //     return accessory.group != ACCESSORY_GROUP_SENSOR && accessory.group != ACCESSORY_GROUP_STATUS_DISPLAY
      //   })
    },
  },
  methods: {
    getActions,
    className,
    getAccessory() {
      return this.$store.getters.accessories
    },
    onAction: function(sceneAction) {
      console.log("SceneAction", sceneAction);
    },
  },
  mounted() {
      console.log("Starting connection to WebSocket Server");
      this.connection = new WebSocket(LOCAL_MODE ? LOCAL_IP_2 : "ws://" + location.hostname + ":6655");

      this.connection.onmessage = (event) => {
        let dataString = event.data;
        this.webSocketData = [];

        this.webSocketData = JSON.parse(dataString);
        console.log("component key",this.componentKey)
        this.$store
          .dispatch("getAccessories")
          .then((message) => {
            this.loading = false;
          })
          .catch((reason) => {
            this.loading = false;
          });
    }
  },
  created() {
    this.$store
      .dispatch("getAccessories")
      .then((message) => {
        this.loading = false;
      })
      .catch((reason) => {
        this.loading = false;
      });
      if (getFiltersFromLocalStorage()) {
        this.changeFilter(getFiltersFromLocalStorage())
      }
  },
  activated() {
    this.accessories = this.$store.getters.getAccessory
    console.log("Action Edit activated", this.accessories);
  },
  deactivated() {
    console.log("Action Edit deactivated");
  },
};
</script>

<style scoped>
</style>
