var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-card",
    { staticStyle: { "min-height": "345px" } },
    [
      _c("Header", {
        attrs: {
          slot: "header",
          title: _vm.accessory.name,
          subTitle: _vm.roomName,
          icon: _vm.accessory.icon
        },
        slot: "header"
      }),
      _c("div", { staticClass: "content-container" }, [
        _c(
          "div",
          { staticStyle: { height: "230px" } },
          [
            _c(
              "div",
              {
                staticClass: "progress",
                staticStyle: { cursor: "pointer" },
                on: { click: _vm.onSwitch }
              },
              [
                _c("el-progress", {
                  attrs: { type: "circle", percentage: _vm.accessory.level }
                })
              ],
              1
            ),
            _c("el-slider", {
              on: { change: _vm.onChangeLevel },
              model: {
                value: _vm.accessory.level,
                callback: function($$v) {
                  _vm.$set(_vm.accessory, "level", $$v)
                },
                expression: "accessory.level"
              }
            })
          ],
          1
        )
      ])
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }